* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background: linear-gradient(181deg, #010b47, #000428, #2d3aaf, #d4eca5, #d4eca5);
    background-size: 300% 300%;
    animation: gradient-animation 20s ease infinite;
    color: #F6F6F6;
    font-family: 'Roboto', sans-serif;
    font-size: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/*------------------------------------------------------HEADER------------------------------------------------------*/
#mode,
#logo {
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

#mode {
    position: absolute;
    right: 0;
    margin-right: 20px;
    margin-top: -20px;
}


.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #000000;
}

.logo {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.header-container {
    display: flex;
    align-items: center;
}


.menu ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu ul li {
    margin-right: 24px;
    margin-top: 13px;
    margin-bottom: 13px;
    font-size: 1rem;
}

.menu ul li a {
    text-align: center;
    font-size: 1rem;
}

/*---------------------------------------------TITLE AREA------------------------------------------------------*/
#intro-area {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 12%;
}

.name {
    color: #d4eca5;

}

.hero-image {
    width: 50%;
    border-radius: 50%;
}

h1,
h2,
h3 {
    margin-bottom: 48px;
}

h1 {
    font-size: 5rem;
    font-weight: 600px;
    line-height: 115px;
}

.name {
    margin-top: -25px;
}

h2 {
    font-size: 2rem;
    font-weight: 600px;
    text-transform: uppercase;
    color: #002557;
    margin-bottom: 0;

}


h3 {
    font-size: 38px;
    font-weight: normal;
    line-height: 56px;
}

#profession {
    margin-top: -25px;
}

.subtitle {
    font-size: 16px;
    font-weight: 600px;
    line-height: 28px;
    text-transform: uppercase;
    margin-bottom: 24px;
    margin-top: 15px;
    color: #d4eca5;
}

.func {
    margin-top: -20px;

}

a {

    text-decoration: none;
    color: #F6F6F6;
    text-transform: uppercase;

}

a:hover {
    color: #d4eca5;
    ;
}

.link {
    font-size: 1rem;
    font-weight: 600px;
    line-height: 28px;
    text-decoration: none;
    color: #0e1023;
    text-transform: uppercase;
}

.link:hover {
    color: #d4eca5;
}

.hero {
    height: 100vh;
}

.hero-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-top: 100px;
}

.hero-cta {
    margin-top: 50px;
    text-align: center;
}

.hero-cta.button {
    display: block;
    margin: 0 auto;
    max-width: 200px;
    font-size: 16px;
    line-height: 24px;
}

#button-area {
    margin-left: 10%;
}

.button {
    background-color: #F7F7F7;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 12px 24px;
    border: none;
    border-radius: 36px;
    margin-right: 24px;
}

.button:hover {
    background-color: #d4eca5;
    color: white;
}


/*-------------------------------------------------DATA AREA------------------------------------------------------*/

.area-title {
    text-align: center;
    font-size: 6rem;
}

.box {
    padding: 24px;
    background-color: rgba(0, 1, 2, 0.414);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 24px;
    margin-bottom: 24px;
    margin-top: 100px;

}

#project-box {
    width: 87%;
}

.me-container {
    width: 90%;
    margin: 0 auto;
    border-radius: 25px;
    height: auto !important;
}

ul,
p {
    margin-left: 5%;
}

#name-area {
    margin-top: 0;
}


.container ul {
    margin-left: 5%;
}

section {
    padding-top: 64px;
    padding-bottom: 64px;

}

#first {
    height: 30%;
}

#info {
    margin-top: -10%;

}

.works-menu {
    margin-top: 0;
}

.works-menu ul,
.me-menu ul {
    list-style-type: none;
    display: flex;
}

.works.menu ul li,
.me-menu ul li {
    margin-right: 24px;
}

.works {
    width: 90%;
    margin: 0 auto;
    margin-top: 100px;
    max-width: 1140px;


}

.aboutme {
    margin-top: -15%;

}

#interests {
    margin-top: 0;
}

#myskills {
    margin-top: 0;
}

#myexp {
    margin-top: 0;
}

.works-item {
    margin-bottom: 5%;
    border-radius: 25px;
    padding: 25px;
    background-color: #000428;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    height: auto;
}

.work-info {
    display: flex;
    flex-direction: column;
    width: auto;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 1.5rem;
}

.work-info-label {

    text-transform: uppercase;

}

.work-info-content {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 0 auto;
}

.works-item-tags {
    font-size: 24px;
    color: #002557;
}

.lang_skill {
    border-radius: 50px;
    font-size: 1em;
    width: 15%;
    height: 100px;
    text-align: center;
    padding: 12px;
    margin-right: 24px;
    margin-top: 24px;
    display: inline-block;
}

.exp {
    border: 3px solid #d4eca5;
    border-radius: 50px;
    font-size: 1em;
    width: 15%;
    height: 50px;
    text-align: center;
    padding: 12px;
    margin-right: 24px;
    margin-top: 24px;
    display: inline-block;
}

#exp_row {
    margin-top: -10%;
}

.exp-desc {
    margin-top: -15%;
}

.lang_skill:hover {
    background-color: #d8f3dc;
    color: #0B2D32;
}

.int_emoji {
    border: 3px solid #d4eca5;
    border-radius: 50px;
    font-size: 1em;
    width: 15%;
    height: 50px;
    text-align: center;
    padding: 12px;
    margin-right: 24px;
    margin-top: 24px;
    display: inline-block;
}

.int_emoji:hover {
    background-color: #d8f3dc;
    color: #0B2D32;
}


.row {
    text-align: center;
}

.edu {
    margin-top: 150px;
    margin-bottom: 50px;
    width: 300px;
    height: 200px;
}

.edu:hover {
    background-color: #d8f3dc;
    color: #0B2D32;

}

.edu_years {
    margin-top: 10px;
}

#edu_row {
    margin-top: -100px;
}



/*-------------------------------------------------FOOTER------------------------------------------------------*/
footer {
    background-color:  #000428;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    text-align: center;
    padding: 20px;
    position: static;
    left: 0;
    bottom: 0;
    width: 100%;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.footer-logo {
    font-size: 20px;
}

.footer-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-menu ul li {
    display: inline-block;
    margin-right: 20px;
}

.footer-menu ul li:last-child {
    margin-right: 0;
}

.footer-menu ul li a {
    text-decoration: none;
}

.footer-menu ul li a:hover {
    text-decoration: underline;
}


.container {
    width: 80%;
    margin: 0 auto;
    border-radius: 25px;

}

#edubox {
    margin-top: 10%
}

.footer_info {
    display: inline-block;
    margin-right: 100px;
    margin-left: 100px;

}

.footer_link {
    font-size: 16px;
    font-weight: 600px;
    line-height: 28px;
    text-decoration: none;
    text-transform: uppercase;
    color: #F7F7F7;
}

.footer_link:hover {
    color: #d4eca5;
}

#social {
    margin-top: 25px;
}

.social_icon {
    margin: 5px;
    display: inline-block;
    width: 2rem;

}

#copyright {
    margin-top: 25px;
    font-size: 0.6em;

}

#myedu {
    margin-top: -10%;
}



/*----------------------------------------------------------RESPONSIVE-----------------------------------------------------*/
/* Aggiunta di regole per i dispositivi di dimensioni inferiori a 768px */
@media screen and (max-width: 768px) {


    .logo {
        width: 100px;
        /* Imposta la larghezza del logo */
        height: 68px;
        /* Imposta l'altezza del logo */
    }

    .menu {
        display: none;
    }


    .header-container {
        position: relative;
    }

    .aboutme {
        margin-top: -50%;

    }

    .name {
        margin-top: 15px;
    }

    #me {
        width: 45%;
        margin-top: 15%;
    }

    #me_button {
        margin-top: 5%;
    }

    #infobutton {
        margin-top: 5%;
    }

    #first {
        height: 90%;

    }

    #info {
        margin-top: -75px;
    }

    .container {
        width: 90%;
    }

    .title {
        font-size: 68px;
        line-height: 70px;
        margin-top: 50px;

    }

    .link {
        font-size: 12px;
    }

    #skills {
        margin-top: 25px;
    }


    #edubox2 {
        margin-top: 0;
    }

    #edu_row {
        margin-top: 0;
    }

    .edu_info {
        margin-top: -35px;
        font-size: 1em;
    }

    .edu_years {
        font-size: 2em;

    }

    .lang_skill {
        width: 35%;
        margin: 20px;

    }

    .int_emoji {
        width: 35%;
        margin: 20px;
    }

    .edu {
        width: 80%;
        margin: 20px;
    }


    h3 {
        font-size: 18px;
        margin-top: -30px;
    }

    .header {
        font-size: 18px;
        padding: 10px;
    }

    .logo {
        background-size: 88px;
        padding-left: 40px;
        background-position: center;

    }

    .menu ul li {
        margin-right: 12px;
    }

    .hero h1 {
        font-size: 48px;
        line-height: 56px;
    }

    .hero-cta {
        margin-top: 24px;
    }

    .works {
        width: 90%;

    }

    .text {
        margin-left: -50px;
        text-align: justify;
    }

    .subtitle {
        margin-left: -50px;
    }



    .footer {
        padding: 10px;
        font-size: 12px;

        text-align: center;
    }


    .footer-logo {
        font-size: 16px;
    }

    .footer-menu ul li {
        margin-right: 12px;
    }

    .transition-effect {
        transition: background-color 0.5s, color 0.5s;
    }

    .works-menu {
        visibility: hidden;
        display: none;
    }
}

@media screen and (max-width: 481px) {
    #intro-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10%;
    }

    .work-info {
        display: flex;
        flex-direction: column;
        width: auto;
        margin-left: 5%;
        margin-right: 5%;
        font-size: 1rem;
    }

    .me-container {
        width: 95%;
        margin: 0 auto;
        height: auto;

    }

    .me-container h2 {
        font-size: 1.5rem;
    }

    .me-container .work-info {
        font-size: 0.9rem;
    }

    .works-item-tags {
        font-size: 1rem;

    }

    .area-title {
        font-size: 3rem;
    }
}

/*----------------------------TIMELINE------------------*/
.vertical-timeline-element-title {
    color: #000000;
    font-size: 1.5rem;
}

.vertical-timeline-element-subtitle {
    color: #000000;
}

#exp-points {
    color: #000000;
}

.timeline {
    margin-bottom: 15%;
}